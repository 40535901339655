import { ArticleIcon, ContactSupportIcon, HomeIcon, Link, ListItemIcon, MenuItem, useLocation } from '@sgde/core';
import { ROUTES } from '../../router/routes.ts';

export const Menu = () => {
  const { pathname } = useLocation();

  return (
    <>
      <MenuItem component={Link} href={ROUTES.HOME.path} selected={pathname === ROUTES.HOME.path}>
        <ListItemIcon>
          <HomeIcon fontSize="small" />
        </ListItemIcon>
        {ROUTES.HOME.name}
      </MenuItem>
      <MenuItem component={Link} href={ROUTES.BLOG.path} selected={pathname === ROUTES.BLOG.path}>
        <ListItemIcon>
          <ArticleIcon fontSize="small" />
        </ListItemIcon>
        {ROUTES.BLOG.name}
      </MenuItem>
      <MenuItem component={Link} href={ROUTES.SUPPORT.path} selected={pathname === ROUTES.SUPPORT.path}>
        <ListItemIcon>
          <ContactSupportIcon fontSize="small" />
        </ListItemIcon>
        {ROUTES.SUPPORT.name}
      </MenuItem>
    </>
  );
};
