import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
    maxWidth: theme.breakpoints.values.lg,
    margin: 'auto',
  },
}));
