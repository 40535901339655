import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  FavoriteIcon,
  Link,
  ShareIcon,
  Stack,
  Typography,
} from '@sgde/core';
import { BlogItem } from '../../models/dto/blog/blogItem.ts';
import { formatDate } from '../../utils/formatDate.ts';
import { useStyles } from './MainArticleCard.style.ts';

type Props = {
  article: BlogItem;
};

export const MainArticleCard = ({ article }: Props) => {
  const { classes } = useStyles();

  return (
    <Card>
      <CardActionArea component={Link} href={`/article/${article.id}`}>
        {!!article.hero && (
          <CardMedia image={`${article.hero.url}`} className={classes.cardMedia}>
            <CardContent className={classes.cardContent}>
              <Stack direction="row" className={classes.stack}>
                <Typography variant="caption" className={classes.dateTypography}>
                  {formatDate(article.createdAt)}
                </Typography>
                <CardActions className={classes.actions}>
                  <FavoriteIcon />
                  <Typography variant="caption">23k</Typography>
                  <ShareIcon />
                  <Typography variant="caption">12k</Typography>
                </CardActions>
              </Stack>

              <Typography variant="h6" noWrap className={classes.titleTypography}>
                {article.title}
              </Typography>
              <Typography variant="body1" className={classes.bio}>
                {article.bio}
              </Typography>
            </CardContent>
          </CardMedia>
        )}
      </CardActionArea>
    </Card>
  );
};
