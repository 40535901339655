import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(() => ({
  root: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
}));
