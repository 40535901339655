import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  contentWrapper: {
    width: '100%',
    margin: 'auto',
    padding: `${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    maxWidth: theme.breakpoints.values.xl,
  },
  grid: {
    minHeight: `calc(100vh - ${theme.spacing(33)})`,
  },
}));
