import { Route } from '../models/router/route';

type Routes = Record<string, Route>;

export const ROUTES: Routes = {
  HOME: { path: '/', name: 'Home' },
  BLOG: { path: '/blog', name: 'Blog' },
  ARTICLE: { path: '/article/:id', name: 'Article' },
  SUPPORT: { path: '/support', name: 'Support' },
} as const;
