import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
  bannerBox: {
    height: theme.spacing(60),
    backgroundColor: 'grey',
    backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  layoutSpacing: {
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
    },
    [theme.breakpoints.only('md')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(9)}`,
    },
    [theme.breakpoints.only('sm')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(7)}`,
    },
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(5)}`,
    },
  },
  bannerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('xl')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
      maxWidth: theme.breakpoints.values.xl,
    },
  },
  titleTypography: {
    color: '#fff',
    paddingTop: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '5',
    WebkitBoxOrient: 'vertical',
  },
  authorTypography: {
    color: '#ffffffb3',
  },
  dateTypography: {
    color: '#ffffffb3',
  },
  chip: {
    width: 'fit-content',
    color: '#fff',
    backgroundColor: '#ffffff29',
  },
  mainContent: {
    minHeight: `calc(100vh - ${theme.spacing(90)})`,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.only('md')]: {
      maxWidth: theme.breakpoints.values.xl,
    },
  },
  bioTypography: {
    fontSize: theme.spacing(2.2),
  },
}));
