import { Box } from '@sgde/core';
import { SupportForm } from '../components/SupportForm/SupportForm';
import { useStyles } from './Support.styles';

export const Support = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <SupportForm />
    </Box>
  );
};
