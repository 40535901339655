import { Avatar, Stack, Typography, useLocation, useNavigate, useSite } from '@sgde/core';
import { ROUTES } from '../../router/routes.ts';
import { useStyles } from './Logo.styles.ts';

export const Logo = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const { data: site, isLoading } = useSite();

  const navigateHome = () =>
    navigate([ROUTES.HOME.path, ROUTES.CHAT.path].includes(pathname) ? ROUTES.HOME.path : ROUTES.CHAT.path);

  return (
    <Stack className={classes.root} direction="row" alignItems="center" onClick={navigateHome}>
      {!isLoading && (
        <>
          <Avatar alt="logo" src={site?.logo.url} sx={{ mr: 1 }} />
          <Typography variant="h6" color="primary" noWrap sx={{ letterSpacing: 0 }}>
            {site?.title}
          </Typography>
        </>
      )}
    </Stack>
  );
};
