import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(6),
    gap: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },

    '&:last-child': { paddingBottom: theme.spacing(6) },
    '& .MuiTextField-root': { width: '100%' },

    button: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  alignedCenter: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkboxLabel: {
    padding: theme.spacing(0.8),
  },
  button: {
    marginLeft: 'auto',
    width: '10rem',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
  },
  typographySuccess: {
    color: theme.palette.success.main,
  },
  typographyError: {
    color: theme.palette.error.main,
  },
}));
