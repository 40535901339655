import { BlocksRenderer, Box, type BlocksContent } from '@sgde/core';
import { useStyles } from './ArticleContent.style';

type Props = {
  content: BlocksContent;
};

export const ArticleContent = ({ content }: Props) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.root}>
      <BlocksRenderer content={content} />
    </Box>
  );
};
