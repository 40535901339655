import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    height: 400,
    borderRadius: theme.shape.borderRadius,
  },
  cardMedia: {
    height: '400px',
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: 'grey',
    backgroundBlendMode: 'multiply',
  },
  cardContent: {
    width: '100%',
    height: theme.spacing(25),
  },
  cardActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(1.5),
  },
  bio: {
    color: '#fff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  stack: {
    height: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actions: {
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: 0,
    color: theme.palette.text.secondary,

    svg: {
      fontSize: '1rem',
    },

    span: {
      lineHeight: theme.typography.caption.fontSize,
      '&:not(:nth-child(1))': {
        marginLeft: 0,
      },
    },
  },
  dateTypography: {
    color: '#ffffffb3',
  },
  titleTypography: {
    color: '#fff',
  },
}));
