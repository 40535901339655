import {
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogManager,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SupportMessageDto,
  TextField,
  Typography,
  useSendMessage,
  useSitePolicy,
  validateField,
} from '@sgde/core';
import { useEffect, useState } from 'react';
import { useStyles } from './SupportForm.styles';

export const SupportForm = () => {
  const { classes } = useStyles();
  const { data: policy } = useSitePolicy();
  const [sendMessage, { isSuccess, error }] = useSendMessage();
  const [formData, setFormData] = useState<SupportMessageDto>({
    firstName: '',
    lastName: '',
    phone: '',
    emailAddress: '',
    questionType: '',
    subject: '',
    content: '',
  });
  const [errors, setErrors] = useState<Partial<SupportMessageDto>>({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isPolicyChecked, setPolicyChecked] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setFormData({
        firstName: '',
        lastName: '',
        phone: '',
        emailAddress: '',
        questionType: '',
        subject: '',
        content: '',
      });
      setShowSuccessMessage(true);
      const timer: NodeJS.Timeout = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
    return;
  }, [isSuccess]);

  const handleChange = (key: keyof SupportMessageDto, value: string) => {
    setFormData({ ...formData, [key]: value });
    const errorMessage = validateField<SupportMessageDto>(key, value);
    setErrors({ ...errors, [key]: errorMessage });
  };

  const isSubmitDisabled = () => {
    return (
      Object.values(formData).some(value => !value) || Object.values(errors).some(value => !!value) || !isPolicyChecked
    );
  };

  const handleSubmit = () => {
    sendMessage(formData);
  };

  return (
    <>
      <Card elevation={3}>
        <CardContent className={classes.content}>
          <Typography variant="h4" className={classes.alignedCenter}>
            Formular de asistență tehnică
          </Typography>
          <Typography variant="h6" className={classes.alignedCenter}>
            Echipa de suport este alături de tine de Luni până Vineri, între orele 9:00 - 17:30
          </Typography>
          <Divider sx={{ margin: '0 -24px' }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Prenume"
                variant="outlined"
                value={formData.firstName}
                onChange={({ target: { value } }) => handleChange('firstName', value)}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Nume"
                variant="outlined"
                value={formData.lastName}
                onChange={({ target: { value } }) => handleChange('lastName', value)}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Telefon"
                variant="outlined"
                value={formData.phone}
                onChange={({ target: { value } }) => handleChange('phone', value)}
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Email"
                type="email"
                variant="outlined"
                value={formData.emailAddress}
                onChange={({ target: { value } }) => handleChange('emailAddress', value)}
                error={!!errors.emailAddress}
                helperText={errors.emailAddress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Subiect"
                variant="outlined"
                value={formData.subject}
                onChange={({ target: { value } }) => handleChange('subject', value)}
                error={!!errors.subject}
                helperText={errors.subject}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select">Cu ce te putem ajuta?</InputLabel>
                <Select
                  fullWidth
                  required
                  label="Cu ce te putem ajuta?"
                  labelId="select"
                  value={formData.questionType}
                  onChange={({ target: { value } }) => handleChange('questionType', value)}
                  error={!!errors.questionType}
                >
                  <MenuItem value="Întrebare tehnică">Întrebare tehnică</MenuItem>
                  <MenuItem value="Programare pentru instalare/configurare">
                    Programare pentru instalare/configurare
                  </MenuItem>
                  <MenuItem value="Solicitare curs personalizat">Solicitare curs personalizată</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                multiline
                label="Mesaj"
                variant="outlined"
                minRows={4}
                value={formData.content}
                onChange={({ target: { value } }) => handleChange('content', value)}
                error={!!errors.content}
                helperText={errors.content}
              />
            </Grid>
          </Grid>

          <FormControlLabel
            control={<Checkbox checked={isPolicyChecked} onChange={() => setPolicyChecked(!isPolicyChecked)} />}
            label={<CheckboxLabel openTerms={() => setOpenTerms(true)} openPrivacy={() => setOpenPrivacy(true)} />}
            className={classes.formControlLabel}
          />
          {error && (
            <Typography variant="subtitle1" className={classes.typographyError}>
              Mesajul nu a putut fi trimis. Vă rugăm să încercați din nou.
            </Typography>
          )}
          {showSuccessMessage && (
            <Typography variant="subtitle1" className={classes.typographySuccess}>
              Mesajul a fost trimis cu succes. Vă mulțumim și vă vom contacta în cel mai scurt timp
            </Typography>
          )}
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitDisabled()}
            onClick={handleSubmit}
            className={classes.button}
          >
            Trimite
          </Button>
        </CardContent>
      </Card>
      <DialogManager isOpen={openTerms} content={policy?.termsAndConditions || []} setOpenDialog={setOpenTerms} />
      <DialogManager isOpen={openPrivacy} content={policy?.privacyPolicy || []} setOpenDialog={setOpenPrivacy} />
    </>
  );
};

type Props = {
  openTerms: () => void;
  openPrivacy: () => void;
};

export const CheckboxLabel = ({ openTerms, openPrivacy }: Props) => {
  const { classes } = useStyles();
  return (
    <Typography variant="subtitle1" color="textSecondary" className={classes.checkboxLabel}>
      {` Completând acest formular, sunt de acord cu `}
      <Link color="textSecondary" onClick={openTerms}>
        Termeni și Condiții de Utilizare
      </Link>
      {` și `}
      <Link color="textSecondary" onClick={openPrivacy}>
        Politica de Confidențialitate
      </Link>
      {` ale companiei SGDE APPLICATION SRL. De asemenea, sunt de acord să primesc email-uri de la SGDE APPLICATION SRL și
      înțeleg că pot renunța oricând la acest lucru, prin contactarea firmei. * `}
    </Typography>
  );
};
