import { Box, HeroDto as HeroProps, Stack, Typography, useMediaQuery, useTheme } from '@sgde/core';
import { useStyles } from './Hero.styles.ts';

export const Hero = ({ image, mobileImage, mainText, mainTitle, secondaryText, secondaryTitle }: HeroProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Box className={classes.root}>
      <Box component="img" src={isMobile ? mobileImage?.url ?? image?.url : image?.url} />
      <Box className={classes.content}>
        <Stack spacing={2} className={classes.stack}>
          <Typography variant="h4" className={classes.title}>
            {mainTitle}
          </Typography>
          <Typography variant="body1">{mainText}</Typography>
        </Stack>
        <Stack spacing={2} className={classes.stack}>
          <Typography variant="h4" className={classes.title}>
            {secondaryTitle}
          </Typography>
          <Typography variant="body1">{secondaryText}</Typography>
        </Stack>
      </Box>
    </Box>
  );
};
