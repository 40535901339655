import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  FavoriteIcon,
  Link,
  ShareIcon,
  Typography,
} from '@sgde/core';
import { BlogItem } from '../../models/dto/blog/blogItem.ts';
import { formatDate } from '../../utils/formatDate.ts';
import { useStyles } from './ArticleCard.style.ts';

type Props = {
  article: BlogItem;
};

export const ArticleCard = ({ article }: Props) => {
  const { classes } = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea component={Link} href={`/article/${article.id}`} className={classes.actionArea}>
        <CardMedia className={classes.media} image={`${article.hero.url}`} component="img" />
        <CardContent>
          <Typography variant="caption" color="text.secondary">
            {formatDate(article.createdAt)}
          </Typography>
          <Typography variant="h6" noWrap>
            {article.title}
          </Typography>
          <Typography variant="body1" className={classes.intro}>
            {article.bio}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <FavoriteIcon />
          <Typography variant="caption">23k</Typography>
          <ShareIcon />
          <Typography variant="caption">12k</Typography>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};
