import { createBrowserRouter, createRoutesFromElements, Route } from '@sgde/core';
import { Layout } from '../components/Layout.tsx';
import { Article } from '../pages/Article.tsx';
import { Blog } from '../pages/Blog.tsx';
import { Home } from '../pages/Home.tsx';
import { Support } from '../pages/Support.tsx';
import { ROUTES } from './routes.ts';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/" element={<Home />} />
      <Route path={ROUTES.BLOG.path} element={<Blog />} />
      <Route path={ROUTES.ARTICLE.path} element={<Article />} />
      <Route path={ROUTES.SUPPORT.path} element={<Support />} />
    </Route>
  )
);
