import { Box, Chip, Container, Footer, Stack, Typography, useParams } from '@sgde/core';
import { ArticleContent } from '../components/Content/ArticleContent.tsx';
import { useArticle } from '../store/blogApi';
import { formatDate } from '../utils/formatDate.ts';
import { useStyles } from './Article.styles.ts';

export const Article = () => {
  const { classes, cx } = useStyles();
  const params = useParams();
  const { data: article } = useArticle({ id: Number(params?.id) });

  return (
    <Stack className={classes.root}>
      <Box sx={{ background: `url(${article?.data?.hero?.url})` }} className={classes.bannerBox}>
        <Container className={cx(classes.layoutSpacing, classes.bannerContainer)}>
          <Typography variant="h4" className={classes.titleTypography}>
            {article?.data?.title || ''}
          </Typography>
          <Stack>
            <Typography variant="h6" className={classes.authorTypography}>
              {article?.data?.author}
            </Typography>
            {article?.data?.createdAt && (
              <Typography className={classes.dateTypography}>
                {formatDate(new Date(article?.data?.createdAt))}
              </Typography>
            )}
            {article?.data?.category && (
              <Stack direction="row" spacing={1} flexWrap={'wrap'}>
                {article.data.category.map(category => (
                  <Chip key={category} label={category} className={classes.chip} />
                ))}
              </Stack>
            )}
          </Stack>
        </Container>
      </Box>
      <Box className={classes.mainContent}>
        <Container className={cx(classes.layoutSpacing, classes.contentContainer)}>
          <Typography className={classes.bioTypography}>{article?.data?.bio}</Typography>
        </Container>
        <Container className={cx(classes.layoutSpacing, classes.contentContainer)}>
          <ArticleContent content={article?.data?.content || []}></ArticleContent>
        </Container>
      </Box>
      <Box m={3}>
        <Footer />
      </Box>
    </Stack>
  );
};
