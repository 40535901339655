import { BlocksContent, BlocksRenderer, Dialog, DialogContent } from '@sgde/core';
import React from 'react';

type DialogManagerProps = {
  isOpen: boolean;
  content: BlocksContent;
  setOpenDialog: (isOpen: boolean) => void;
};

export const DialogManager: React.FC<DialogManagerProps> = ({ isOpen, content, setOpenDialog }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setOpenDialog(false);
      }}
    >
      <DialogContent>
        <BlocksRenderer content={content} />
      </DialogContent>
    </Dialog>
  );
};
