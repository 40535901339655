import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  header: {
    '& > .MuiToolbar-root': {
      margin: 'auto',
      width: '100%',
      maxWidth: theme.breakpoints.values.xl,
      padding: `0 ${theme.spacing(3)}`,
    },
  },
  content: {
    flex: 1,
    overflow: 'auto',
  },
}));
