import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    height: 400,
    borderRadius: theme.shape.borderRadius,
  },
  actionArea: {
    height: '100%',
  },
  media: {
    height: 200,
  },
  intro: {
    height: 72,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  actions: {
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    paddingRight: theme.spacing(2),

    svg: {
      fontSize: '1rem',
    },

    span: {
      lineHeight: theme.typography.caption.fontSize,
      '&:not(:nth-child(1))': {
        marginLeft: 0,
      },
    },
  },
}));
