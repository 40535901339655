import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    maxWidth: theme.breakpoints.values.xl,
    margin: 'auto',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
}));
