import {
  Avatar,
  Box,
  DialogManager,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
  useSite,
  useSitePolicy,
} from '@sgde/core';
import { useState } from 'react';
import { useStyles } from './Footer.styles.ts';

export const Footer = () => {
  const { classes } = useStyles();
  const { data: site, isLoading } = useSite();
  const { data: policy } = useSitePolicy();

  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openGdpr, setOpenGdpr] = useState(false);

  return (
    <>
      {!isLoading && (
        <Paper className={classes.paper}>
          <Stack className={classes.rootStack}>
            <Avatar alt="logo" src={site?.logo.url} sx={{ mr: 1 }} className={classes.logo} />
            <Stack className={classes.stack}>
              <Stack
                className={classes.linksContainer}
                divider={<Divider orientation="vertical" flexItem className={classes.divider} />}
              >
                <Link onClick={() => setOpenTerms(true)} className={classes.link}>
                  Termeni și Condiții
                </Link>
                <Link onClick={() => setOpenPrivacy(true)} className={classes.link}>
                  Politica de utilizare Cookie-uri
                </Link>
                <Link onClick={() => setOpenGdpr(true)} className={classes.link}>
                  Prelucrarea datelor cu caracter personal
                </Link>
                <Typography className={classes.contactDetails}>
                  Contact:
                  <Link className={classes.link} href="mailto:office@sgde.ro">
                    office@sgde.ro
                  </Link>
                </Typography>
              </Stack>

              <Box>
                <Typography className={classes.contactDetails}>
                  © {new Date().getFullYear()} SGDE APPLICATION SRL, CIF 29488911, Str. Margaritar Nr. 3, Dumbravita,
                  Timis, 307160. Toate drepturile rezervate.
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Paper>
      )}
      <DialogManager isOpen={openTerms} content={policy?.termsAndConditions || []} setOpenDialog={setOpenTerms} />
      <DialogManager isOpen={openPrivacy} content={policy?.privacyPolicy || []} setOpenDialog={setOpenPrivacy} />
      <DialogManager isOpen={openGdpr} content={policy?.gdprPolicy || []} setOpenDialog={setOpenGdpr} />
    </>
  );
};
