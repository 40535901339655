import { Box, Footer, Grid } from '@sgde/core';
import { ArticleCard } from '../components/Card/ArticleCard';
import { MainArticleCard } from '../components/Card/MainArticleCard.tsx';
import { useArticles } from '../store/blogApi.ts';
import { useStyles } from './Blog.styles.ts';

export const Blog = () => {
  const { classes } = useStyles();
  const { data: articlesResponse } = useArticles();
  const [mainArticle, ...articles] = articlesResponse?.data || [];

  return (
    <Box className={classes.contentWrapper}>
      <Grid container spacing={3} className={classes.grid}>
        {mainArticle && (
          <Grid item xs={12} sm={6} md={8} lg={6}>
            <MainArticleCard article={mainArticle} />
          </Grid>
        )}
        {articles.map(article => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={article.id}>
            <ArticleCard article={article} />
          </Grid>
        ))}
      </Grid>
      <Box mt={3}>
        <Footer />
      </Box>
    </Box>
  );
};
