import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    img: {
      width: '100%',
      [theme.breakpoints.only('xs')]: {
        height: '60%',
      },
    },
    position: 'relative',
  },
  content: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.up('xl')]: {
      bottom: 0,
      padding: theme.spacing(9),
    },
    [theme.breakpoints.down('xl')]: {
      bottom: 0,
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down('lg')]: {
      bottom: 0,
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  stack: {
    transition: 'all 0.3s ease-in-out',
    width: theme.spacing(40),
    textAlign: 'center',
    padding: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '&:hover': {
      scale: '1.05',
    },
  },
  title: {
    fontWeight: 'bold',
  },
}));
